import logo from './logo.svg';
import './App.css';
import Sidebar from "./Components/Sidebar";
import { Routes, Route } from 'react-router-dom';
import Dashboard from "./Pages/Dashboard";
import Datasets from './Pages/Datasets';

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/datasets" element={ <Datasets /> } />
        </Routes>
    </div>
  );
}

export default App;
