import React, { useState } from 'react';

const EditableCell = ({ initialValue, onSave }) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(value); // Save the updated value
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (isEditing) {
    return (
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      />
    );
  }

  return (
    <td className="border p-2 w-full" onDoubleClick={handleDoubleClick}>
      {value}
    </td>
  );
};

export default EditableCell;
