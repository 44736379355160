import {react, useState, useEffect} from "react";
import Sidebar from "../Components/Sidebar";

import { BiPlayCircle } from 'react-icons/bi'
import { Watch } from  'react-loader-spinner'
import EditableCell from "../Components/EditableCell";
import { CSVLink } from 'react-csv';



export default function Dashboard() {
    const [data, setData] = useState('')
    const [status, setStatus] = useState('status')
    const [numbers, setNumbers] = useState('')
    const [loading, setLoading] = useState(false)


    const producTionUrl = "https://scrapper-api.affimedia.nl/api/search/"

    const local = "http://127.0.0.1:8000/api/search/"
    const handleStatus = (newStatus) => {
        setStatus(newStatus)
    }

    let getResults = async(e) => {
        e.preventDefault()
        setData('')
        setLoading(true)
        let response = await fetch(producTionUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "query": e.target.query.value,
                "number_of_results": e.target.number_of_results.value
            })
        })

        let data = await response.json()
        if ( response.status === 200 )
        {
            setData(data.data)
            setLoading(false)
        } else{
            console.log("Failed To fetch results")
        }
    }

    const headers = [
        { label: 'Company Name', key: 'company_name' },
        { label: 'Email', key: 'email' },
        { label: 'Links', key: 'links' },
        { label: 'Numbers', key: 'numbers' }
        // Add more headers as needed
      ];


    return (
        <div className="flex flex-row h-full w-full bg-slate-50">
            <Sidebar className="" />
            <div className="main w-full">
                <div className="m-5">
                    <h1 className="text-left">Search keywords</h1>
                    
                    <form action="" onSubmit={getResults} className="">
                        <div className="flex items-center justify-between">
                            <input type="text" name="query" className="w-full border p-2 my-3 rounded" placeholder="Enter Campaign Name....."/>
                            <select name="number_of_results" required id="" className="p-2.5 border rounded mx-3">
                                <option value="" selected>Select Number Of Results</option>
                                <option value="1">1</option>
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="10000">10,000</option>
                            </select>
                        </div>
                        
                        <button className="w-[170px] p-2 bg-slate-900 text-white rounded">Start Searching</button>
                    </form>
                </div>
                {
                    data && Object.keys(data).length > 0 ? (
                        <div className="w-full">
                        <button className=
                        "w-[230px] bg-slate-900 p-2 rounded-full text-white font-bold transition hover:scale-125 delay-100">
                            <CSVLink 
                            className=""
                            data={data} headers={headers} filename="data.csv">
                                Export to CSV
                            </CSVLink>
                        </button>
                        

                            {/* <button className="bg-slate-900 my-7 w-[230px] p-1.5 rounded-full text-white font-bold transition ease-in-out delay-150 hover:scale-125">
                                File Exported
                            </button> */}
                            <table className="w-full bg-white border text-left container mx-auto my-7">
                            <thead className="">
                                <th className="border text-sm font-bold p-2">Company Name</th>
                                <th className="border text-sm font-bold p-2">Email</th>
                                <th className="border text-sm font-bold p-2">Links</th>
                                <th className="border text-sm font-bold p-2">Numbers</th>

                            </thead>

                            <tbody>
                                {
                                    data.map((item, index) => (
                                        <tr className="cursor-pointer hover:bg-indigo-50" key={item.id}>
                                            <td className="border text-sm font-normal p-2 w-5">
                                                {item.company_name}
                                            </td>
                                            <td className="border text-sm font-normal p-2 w-2">{item.email}</td>
                                            <td className="border text-sm font-normal p-2">
                                                {

                                                    item.links.map((i, index) => (
                                                        <tr className="border">
                                                            <td className="p-2 w-5">{i}</td>
                                                            
                                                        </tr>
                                                    ))
                                                }
                                            </td>
                                            <td className="border text-sm font-normal p-2">
                                               {
                                                    item.numbers.map((n, index) => (
                                                        <tr >
                                                            <td className="border p-2 w-full">{n}</td>
                                                            <td className="border p-2 w-full">
                                                                <EditableCell value="Value" onSave={handleStatus} />
                                                            </td>
                                                        </tr>
                                                    ))
                                               }
                                            </td>
                                            {/* <td className="border text-sm font-normal p-2">ABDCD</td>
                                            <td className="border text-sm font-normal p-2">ABDCD</td> */}
                                        </tr>
                                    ))
                                }
                                
                                
                            </tbody>
                        
                        </table>
                        
                        </div>
                        
                        
                    ) : loading ? (
                        <div className="flex flex-col items-center justify-center text-center my-7">
                            <Watch
                                height="80"
                                width="80"
                                radius="48"
                                color="#111"
                                ariaLabel="watch-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            /> <br />
                            <p className="my-5">Preparing file...</p>
                        </div>
                    ) : (
                        <div></div>
                    )

                    
                }
                

                
                
            </div>
        </div>
    )
}