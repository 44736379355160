import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { Oval } from "react-loader-spinner";
import EditableCell from "../Components/EditableCell";
import InfiniteScroll from "react-infinite-scroll-component";


export default function Datasets(){

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('status')
    const [hasMore, setHasMore] = useState(true)


    // const [numbers, setNumbers] = useState('')
    const url = "http://127.0.0.1:8000/api/datasets/"
    const productionUrl = "https://scrapper-api.affimedia.nl/api/datasets/"
    const fetchDataset = () => {
        setLoading(true)
        fetch(productionUrl, {
            method: "GET"
        })
        .then((response) => {
            return response.json()
        })
        .then((data )=> {
            setData(data.data)
            setLoading(false)
        })
        .catch((error) => {
            return error
        })
    
    }
    useEffect(() => {
        fetchDataset()
    }, [url])
        

    const handleStatus = (newStatus) => {
        setStatus(newStatus)
    }

    const fetchMoreData = () => {
        setTimeout(() => {
            setData(data)
        })
    }

    return(
        <div className="flex flex-row h-full w-full bg-slate-50">
            <Sidebar />
            <div className="main w-full">
                <div className="flex flex-row items-start justify-between m-5">
                    <h1 className="text-2xl">Datasets</h1>
                </div>

                <InfiniteScroll dataLength={data ? data.length : 0}
                    next={fetchMoreData} hasMore={hasMore}
                    loader={
                        <div className="flex flex-col items-center justify-center text-center my-7"> 
                            <Oval
                            height={30}
                            width={30}
                            color="#252729"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#2F90F3"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                            />
                        </div>
                        
                    }
                >

                    {
                        data && Object.keys(data).length > 0 ? (
                            <table className="w-full bg-white border text-left container mx-auto my-7">
                                <thead className="">
                                <th className="border text-sm font-bold p-2">Serializer No.</th>
                                    <th className="border text-sm font-bold p-2">Company Name</th>
                                    <th className="border text-sm font-bold p-2">Email</th>
                                    {/* <th className="border text-sm font-bold p-2">Links</th> */}
                                    <th className="border text-sm font-bold p-2">Numbers</th>
                                </thead>
                                <tbody>
                                    {
                                        data.map((item, index) => (
                                            <tr className="cursor-pointer" key={item.id}>
                                                <td className="border text-sm font-normar p-2 w-[220px]">
                                                    {index+1}
                                                </td>
                                                <td className="border text-sm font-normal p-2 w-5">
                                                    {item.company_name}
                                                </td>
                                                <td className="border text-sm font-normal p-2 w-2">{item.email}</td>
                                                {/* <td className="border text-sm font-normal p-2">
                                                    {

                                                        item.links.map((i, index) => (
                                                            <tr className="border">
                                                                <td className="p-2 w-5">{i}</td>
                                                                
                                                            </tr>
                                                        ))
                                                    }
                                                </td> */}
                                                <td className="border text-sm font-normal p-2">
                                                    {
                                                        item.phone_number.map((number, index) => (
                                                            <tr className="w-full">
                                                                <td className="border p-2 w-[300px]">{number}</td>
                                                                <td className="border p-2 w-[300px]">
                                                                    <EditableCell value="Value" onSave={handleStatus} />
                                                                </td>
                                                            </tr>
                                                            
                                                                
                                                                
                                                            
                                                        ))
                                                    }
                                                </td>
                                        
                                                {/* <td className="border text-sm font-normal p-2">ABDCD</td>
                                                <td className="border text-sm font-normal p-2">ABDCD</td> */}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        ) : loading ? (
                            <div className="flex flex-col items-center justify-center text-center my-7">
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#252729"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#2F90F3"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}

                                />
                            </div>
                        ) : <div>NO DATA FOUND</div>
                    }
            </InfiniteScroll>
                
            </div>
            
        </div>
    )
}