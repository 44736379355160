import { react, useEffect, useState } from "react";
import {CiCircleList} from 'react-icons/ci'
import { BiData } from 'react-icons/bi'
import { CiLinkedin, CiFacebook, CiSettings } from 'react-icons/ci'

export default function Sidebar() {
    return (
        <div className="bg-slate-900 h-screen w-[300px] sticky top-0">
            <div className="flex flex-col">
                <div className="flex flex-col items-center justify-center my-4 p-2">
                    <a href="/" className="text-xl font-semibold text-white">LEADSCRAPPER.NL</a>
                    <small className="text-lg text-white">(An Affimedia Product)</small>
                </div>
                
                <div className="flex flex-row items-start justify-start py-5 transition-auto delay-100">
                    <CiCircleList className="text-white text-md mx-4 text-2xl" />
                    <a href="/" className="text-md font-semibold text-white">Dashboard</a>
                </div>
                <div className="flex flex-row items-center justify-start py-5 transition-auto delay-100">
                    <BiData className="text-white text-md mx-4 text-2xl" />
                    <a href="/datasets" className="text-md font-semibold text-white">Show Datasets</a>
                </div>
                <div className="flex flex-row items-start justify-start py-5 transition-auto delay-100">
                    <CiLinkedin className="text-white text-md mx-4 text-2xl" />
                    <a href="/" className="text-md font-semibold text-white">Search on Linked In</a>
                </div>
                <div className="flex flex-row items-center justify-start py-5 transition-auto delay-100">
                    <CiFacebook className="text-white text-md mx-4 text-2xl" />
                    <a href="/" className="text-md font-semibold text-white">Search on Facebook</a>
                </div>
                <div className="flex flex-row items-center justify-start py-5 transition-auto delay-100">
                    <CiSettings className="text-white text-md mx-4 text-2xl" />
                    <a href="/" className="text-md font-semibold text-white">Settings</a>
                </div>
            </div>
        </div>
    )
}